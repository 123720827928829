<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span>
                    {{ form.id ? 'Edição' : 'Cadastro' }} de Categoria de Imposto
                </span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                style="height: 450px; overflow-x: auto;"
            >
                <v-row class="mt-4">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Nome"
                            v-model="form.nome"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="NCM"
                            v-model="form.ncm"
                            v-mask="['##########']"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="form.cfop_saida"
                            :items="cfopSaida"
                            label="CFOP Saída"
                            item-value="id"
                            :item-text="e => `${e.id} | ${e.descricao}`"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="form.cfop_entrada"
                            :items="cfopEntrada"
                            label="CFOP Entrada"
                            item-value="id"
                            :item-text="e => `${e.id} | ${e.descricao}`"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">ICMS</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="form.icms_cst"
                                :items="icmsCst"
                                label="CST/CSOSN"
                                item-value="id"
                                :item-text="e => `${e.id} | ${e.descricao}`"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Valor (%)"
                                v-model="form.icms_valor"
                                v-mask="['#.##', '##.##']"
                                suffix="%"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>
                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">IPI</legend>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="form.ipi_cst"
                                :items="ipiCst"
                                label="CST"
                                item-value="id"
                                :item-text="e => `${e.id} | ${e.descricao}`"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </fieldset>
                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">PIS/COFINS</legend>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="form.pis_cst"
                                :items="pisCst"
                                label="CST"
                                item-value="id"
                                :item-text="e => `${e.id} | ${e.descricao}`"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </fieldset>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CategoriaImposto',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        form: {
            nome: '',
            ncm: '',
            cfop_saida: '',
            cfop_entrada: '',
            icms_cst: '',
            icms_valor: '',
            ipi_cst: '',
            pis_cst: '',
            ativo: true,
        },
        cfopSaida: [
            { id: 5101, descricao: 'Venda producao do estabelecimento'},
            { id: 5102, descricao: 'Venda mercadoria adquirida ou recebida de terceiros'},
            { id: 5405, descricao: 'Venda de mercadoria, adquirida ou recebida de terceiros, sujeita ao regime de substituição tributária, na condição de contribuinte-substituído'},
            { id: 5949, descricao: 'Outra saída de mercadoria ou prestação de serviço não especificado'},
        ],
        cfopEntrada: [
            { id: 1101, descricao: 'Compra para industrialização ou produção'},
            { id: 1102, descricao: 'Compra para comercialização'},
            { id: 1556, descricao: 'Compra de material para uso ou consumo'},
            { id: 1403, descricao: 'Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária'},
        ],
        icmsCst: [
            { id: 101, descricao: 'Tributação pelo Simples com Permissão de Crédito'},
            { id: 102, descricao: 'Tributação pelo Simples sem Permissão de Crédito'},
            { id: 103, descricao: 'Tributação pelo Simples Nacional com Isenção do ICMS para faixa de receita bruta'},
            { id: 500, descricao: 'ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'},
            { id: 400, descricao: 'Não tributada pelo Simples Nacional'},
        ],
        ipiCst: [
            {id: 53, descricao: 'Saída não-tributada'},
            {id: 99, descricao: 'Outras saídas'},
        ],
        pisCst: [
            {id: '01', descricao: 'Operação Tributável com Alíquota Básica'},
            {id: '06', descricao: 'Operação Tributável a Alíquota Zero'},
            {id: '49', descricao: 'Outras Operações de Saída'},
            {id: '99', descricao: 'Outras Operações'},
        ],
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        }
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`categorias-imposto/${id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('categorias-imposto', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-fieldset {
    border-color: #999;
    border-radius: 5px;
    padding: 8px;
}

.v-legend {
    color: #707070;
}

.v-expansion-panel-content {
    margin-top: 16px;
}
</style>
